import '../stylesheets/devise'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import "popper.js"
import 'bootstrap/dist/js/bootstrap'
import './devise_extra.js';