$(function() {
  "use strict";
  $("#sidebarToggle, #sidebarToggleTop").on("click", function(e) {
    $("body").toggleClass("sidebar-toggled"), $(".sidebar").toggleClass("toggled"), $(".sidebar").hasClass("toggled") && $(".sidebar .collapse").collapse("hide")
  }), $(window).resize(function() {
    $(window).width() < 768 && $(".sidebar .collapse").collapse("hide")
  }), $("body.fixed-nav .sidebar").on("mousewheel DOMMouseScroll wheel", function(e) {
    if ($(window).width() > 768) {
      var l = e.originalEvent,
        t = l.wheelDelta || -l.detail;
      this.scrollTop += 30 * (t < 0 ? 1 : -1), e.preventDefault()
    }
  }), $(document).on("scroll", function() {
    $(this).scrollTop() > 100 ? $(".scroll-to-top").fadeIn() : $(".scroll-to-top").fadeOut()
  }), $(document).on("click", "a.scroll-to-top", function(e) {
    var l = $(this);
    $("html, body").stop().animate({
      scrollTop: $(l.attr("href")).offset().top
    }, 1e3, "easeInOutExpo"), e.preventDefault()
  })
});